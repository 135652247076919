<template>
  <span>
    <h5>{{ field.label }} Suche</h5>
    <b-form-group>
      <b-form-input
        v-model="searchInput"
        size="sm"
        placeholder="Suche"
        class="mb-2"
      />
    </b-form-group>
  </span>
</template>

<script>
export default {
  name: 'FilterSearchPopulate',
  props: {
    field: {
      type: Object,
      required: true
    },
    opened: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      searchInput: ''
    }
  },
  computed: {
    label () {
      return (option) => {
        if (!option.label && this.field.formatter) {
          return this.field.formatter(option.value)
        } else if (!option.label) {
          return option.value
        } else {
          return option.label
        }
      }
    },
    query () {
      if (this.searchInput == '') return {} // eslint-disable-line eqeqeq
      return {
        $and: [
          {
            $searchInPopulate: {
              targetKey: this.field.filter.targetKey,
              serviceKey: this.field.filter.serviceKey,
              service: this.field.filter.service,
              searchInput: this.searchInput
            }
          }
        ]
      }
    }
  },
  watch: {
    searchInput: {
      handler: 'updateFilterStatus'
    }
  },
  methods: {
    async updateFilterStatus () {
      this.$emit('change', {
        isApplied: (this.searchInput !== ''),
        query: this.query
      })
    },
    deleteFilter () {
      this.searchInput = ''
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
